import React, { MouseEventHandler } from 'react';
import { Stack } from '@mui/material';
import { breakpoints, LinkOverlay, LinkOverlayContainer, Text, V2Button, v2Colors } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { CARD_WIDTH, CARD_MARGIN, CARD_WIDTH_MOBILE, CARD_MARGIN_MOBILE } from './TestimonialCarouselConstants';

export interface TestimonialCardContent {
    statistic: string;
    statisticDescription: string;
    author: {
        name: string;
        title: string;
        quote: string;
    };
    company: string;
    button: {
        text: string;
        link: string;
    };
}

interface TestimonialCardProps {
    cardContent: TestimonialCardContent;
    isDragging?: boolean;
}

const classes = {
    cardContainer: {
        width: `${CARD_WIDTH}px`,
        padding: '2.8rem',
        marginRight: `${CARD_MARGIN}px`,
        boxSizing: 'border-box',
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
        backgroundColor: v2Colors.background.white,
        flexShrink: 0,
        userSelect: 'none',
        cursor: 'pointer',
        transition: 'scale 0.3s ease-in-out',
        willChange: 'translate',
        '&:hover': {
            borderColor: v2Colors.border.activePrimary,
            scale: '1.04',
        },
        '&:focus-within': {
            borderColor: v2Colors.border.activePrimary,
            outline: `1px solid ${v2Colors.border.activePrimary}`,
            outlineOffset: '3px',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            width: `${CARD_WIDTH_MOBILE}px`,
            marginRight: `${CARD_MARGIN_MOBILE}px`,
        },
    },
    linkButton: (isDragging?: boolean) =>
        ({
            position: 'static',
            pointerEvents: isDragging ? 'none' : 'auto',
        }) as const,
} as const;

export function TestimonialCard({ cardContent, isDragging }: TestimonialCardProps): JSX.Element {
    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event): void => {
        event.currentTarget.blur();
    };

    return (
        <LinkOverlayContainer css={classes.cardContainer} className='carousel-card'>
            <Stack height='100%'>
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    width='70%'
                    component='p'
                    css={{ marginBottom: '16px !important' }}
                >
                    <Text variant='h1' component='span' color='activePrimary'>
                        {cardContent.statistic}
                    </Text>
                    <Text variant='body2' component='span' color='activePrimary' weight='bold'>
                        {cardContent.statisticDescription.toUpperCase()}
                    </Text>
                </Stack>
                <RichTextContent
                    variant='body2'
                    color='secondary'
                    content={`"${cardContent.author.quote}"`}
                    css={{ marginBottom: '24px !important' }}
                />
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-end'
                    columnGap={2}
                    css={{ marginTop: 'auto !important' }}
                >
                    <Stack justifyContent='flex-end' flex={1}>
                        <Text color='secondary'>
                            {cardContent.author.name}, {cardContent.author.title}
                        </Text>
                        <Text color='secondary' weight='bold'>
                            {cardContent.company}
                        </Text>
                    </Stack>
                    <V2Button
                        component={LinkOverlay}
                        href={cardContent.button.link}
                        onClick={handleClick}
                        draggable={false}
                        variant='secondary'
                        size='small'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label={`Read more about ${cardContent.statistic} ${cardContent.statisticDescription}`}
                        css={classes.linkButton(isDragging)}
                    >
                        {cardContent.button.text}
                    </V2Button>
                </Stack>
            </Stack>
        </LinkOverlayContainer>
    );
}
