import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { Skeleton } from '@mui/material';
import { breakpoints } from '@web-for-marketing/react-ui';
import { checkIfReduceMotion } from '@helpers/browserHelper';
import { TestimonialCard, TestimonialCardContent } from './TestimonialCard';
import { CARD_WIDTH, CARD_MARGIN, CARD_WIDTH_MOBILE, CARD_MARGIN_MOBILE } from './TestimonialCarouselConstants';

interface TestimonialCarouselProps {
    testimonialCards: TestimonialCardContent[];
    scrollDirection: 'left' | 'right';
    scrollSpeed: number;
    autoplay?: boolean;
}

const classes = {
    carouselContainer: {
        width: '100%',
        overflowX: 'hidden',
        padding: '2rem 0',
        [`@media (max-width: ${breakpoints.md})`]: {
            padding: '1rem 0',
        },
    },
    carousel: {
        display: 'flex',
    },
} as const;

export function TestimonialCarousel({
    testimonialCards,
    scrollDirection,
    scrollSpeed,
    autoplay = false,
}: TestimonialCarouselProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [isDragging, setIsDragging] = useState(false);
    const [isRunning, setIsRunning] = useState(autoplay);
    const [carouselItems, setCarouselItems] = useState<TestimonialCardContent[]>([]);
    const carouselContainerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<number | null>(null);
    const dragStartX = useRef<number | null>(null);
    const tabScroll = useRef(false);
    const reduceMotion = useRef(false);
    const carouselItemWidth = isMobile ? CARD_WIDTH_MOBILE + CARD_MARGIN_MOBILE : CARD_WIDTH + CARD_MARGIN;

    const updateCarouselItemPositions = useCallback(
        (movementX: number) => {
            if (carouselContainerRef.current) {
                const carouselContainer = carouselContainerRef.current;
                const carouselCards: HTMLElement[] = Array.from(carouselContainer.querySelectorAll('.carousel-card'));

                // Loop over each carousel item and adjust the translate-x property to create an inifiite carousel animation
                carouselCards.forEach((item, index) => {
                    const currentTranslateX = parseFloat(item.style.translate?.split(' ')[0]) || 0;
                    const itemPosition = index + 1;

                    // Keep carousel items within the bounds of the carousel container
                    let newTranslateX = currentTranslateX + movementX;

                    const maxTranslateX = (carouselCards.length - itemPosition) * carouselItemWidth;
                    const minTranslateX = -itemPosition * carouselItemWidth;

                    // Wrap item to back of carousel
                    if (newTranslateX < minTranslateX) {
                        newTranslateX += maxTranslateX - minTranslateX;
                        // Wrap item to front of carousel
                    } else if (newTranslateX > maxTranslateX) {
                        newTranslateX -= maxTranslateX - minTranslateX;
                    }

                    // Offset the native browser scrolling to continue applying carousel movement via modifying the translate property
                    if (tabScroll.current) {
                        carouselContainer.scrollBy({ left: movementX });
                    }

                    item.style.translate = `${newTranslateX}px 0`;
                });
            }
        },
        [carouselItemWidth]
    );

    const startAnimation = useCallback(() => {
        const animate = (): void => {
            updateCarouselItemPositions(scrollSpeed * (scrollDirection === 'left' ? -1 : 1));
            animationRef.current = requestAnimationFrame(animate);
        };
        animationRef.current = requestAnimationFrame(animate);
    }, [scrollDirection, scrollSpeed, updateCarouselItemPositions]);

    const stopAnimation = (): void => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }
    };

    const pauseAnimation = (): void => setIsRunning(false);

    const resumeAnimation = (): void => setIsRunning(true);

    useEffect(() => {
        reduceMotion.current = checkIfReduceMotion(window);
    }, []);

    useEffect(() => {
        if (!reduceMotion.current && isRunning) {
            startAnimation();
        }

        return () => {
            stopAnimation();
        };
    }, [isRunning, startAnimation]);

    const handleCarouselMouseDragStart = useCallback((event: MouseEvent): void => {
        dragStartX.current = event.clientX;
        tabScroll.current = false;
        pauseAnimation();
    }, []);

    const handleCarouselMouseDrag = useCallback(
        (event: MouseEvent): void => {
            if (dragStartX.current) {
                updateCarouselItemPositions(event.movementX);

                if (Math.abs(event.movementX) > 2) {
                    setIsDragging(true);
                }
            }
        },
        [updateCarouselItemPositions]
    );

    const handleCarouselMouseDragEnd = (): void => {
        setIsDragging(false);
        dragStartX.current = null;
    };

    const handleCarouselTouchDragStart = useCallback((event: TouchEvent): void => {
        dragStartX.current = event.touches[0].clientX;
        pauseAnimation();
    }, []);

    const handleCarouselTouchDrag = useCallback(
        (event: TouchEvent): void => {
            if (dragStartX.current) {
                const movementX = event.touches[0].clientX - dragStartX.current;
                updateCarouselItemPositions(movementX);

                if (Math.abs(movementX) > 2) {
                    setIsDragging(true);
                }

                dragStartX.current = event.touches[0].clientX;
            }
        },
        [updateCarouselItemPositions]
    );

    const handleCarouseTouchlDragEnd = useCallback((): void => {
        setIsDragging(false);
        resumeAnimation();
        dragStartX.current = null;
    }, []);

    const handleFocus = useCallback((): void => {
        pauseAnimation();
        if (tabScroll.current && carouselContainerRef.current) {
            if (carouselContainerRef.current.scrollLeft !== 0) {
                updateCarouselItemPositions(-carouselContainerRef.current.scrollLeft);
            }
        }
    }, [updateCarouselItemPositions]);

    const handleBlur = useCallback((): void => {
        if (carouselContainerRef.current) {
            const carouselContainer = carouselContainerRef.current;
            setTimeout(() => {
                // Only resume animation if the focus is outside of the carousel container
                if (!carouselContainer.contains(document.activeElement)) {
                    tabScroll.current = false;
                    resumeAnimation();
                }
            }, 0); // Small delay to ensure focus state updates
        }
    }, []);

    const handleKeyDown = useCallback((event: KeyboardEvent): void => {
        if (event.key === 'Tab') {
            tabScroll.current = true;
        }
    }, []);

    useEffect(() => {
        // Calculate the minimum number of carousel item duplications needed to create an infinite carousel on all screen sizes
        const screenWidth = window.innerWidth;
        const minItemsNeeded = Math.ceil(screenWidth / carouselItemWidth) + 2;
        const duplications = Math.ceil(minItemsNeeded / testimonialCards.length);
        setCarouselItems(Array(duplications).fill(testimonialCards).flat());
    }, [carouselItemWidth, testimonialCards]);

    useEffect(() => {
        const carouselContainer = carouselContainerRef.current;
        if (carouselContainer) {
            carouselContainer.addEventListener('mousedown', handleCarouselMouseDragStart, true);
            carouselContainer.addEventListener('mousemove', handleCarouselMouseDrag);
            carouselContainer.addEventListener('touchstart', handleCarouselTouchDragStart, true);
            carouselContainer.addEventListener('touchmove', handleCarouselTouchDrag);
            carouselContainer.addEventListener('focus', handleFocus, true);
            carouselContainer.addEventListener('blur', handleBlur, true);
            carouselContainer.addEventListener('keydown', handleKeyDown);
        }
        document.addEventListener('mouseup', handleCarouselMouseDragEnd);
        document.addEventListener('touchend', handleCarouseTouchlDragEnd);

        return () => {
            if (carouselContainer) {
                carouselContainer.removeEventListener('mousedown', handleCarouselMouseDragStart, true);
                carouselContainer.removeEventListener('mousemove', handleCarouselMouseDrag);
                carouselContainer.removeEventListener('touchstart', handleCarouselTouchDragStart, true);
                carouselContainer.removeEventListener('touchmove', handleCarouselTouchDrag);
                carouselContainer.removeEventListener('focus', handleFocus, true);
                carouselContainer.removeEventListener('blur', handleBlur, true);
                carouselContainer.removeEventListener('keydown', handleKeyDown);
            }
            document.removeEventListener('mouseup', handleCarouselMouseDragEnd);
            document.removeEventListener('touchend', handleCarouseTouchlDragEnd);
        };
    }, [
        handleBlur,
        handleCarouseTouchlDragEnd,
        handleCarouselMouseDrag,
        handleCarouselMouseDragStart,
        handleCarouselTouchDrag,
        handleCarouselTouchDragStart,
        handleFocus,
        handleKeyDown,
    ]);

    return (
        <div ref={carouselContainerRef} css={classes.carouselContainer}>
            <div onMouseEnter={pauseAnimation} onMouseLeave={resumeAnimation} draggable={false} css={classes.carousel}>
                {carouselItems.length
                    ? carouselItems.map((item, index) => (
                        <TestimonialCard key={index} cardContent={item} isDragging={isDragging} />
                    ))
                    : Array.from(new Array(10)).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant='rectangular'
                            animation='wave'
                            width={`${!isMobile ? CARD_WIDTH : CARD_WIDTH_MOBILE}px`}
                            height={330}
                            css={{ marginRight: `${!isMobile ? CARD_MARGIN : CARD_MARGIN_MOBILE}px`, flexShrink: 0 }}
                        />
                    ))}
            </div>
        </div>
    );
}
