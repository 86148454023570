import React from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { Stack } from '@mui/material';
import { breakpoints, Heading, Text } from '@web-for-marketing/react-ui';
import { CustomSectionComponentProps } from '@models/customSection';
import { TestimonialCardContent } from './TestimonialCard';
import { TestimonialCarousel } from '@components/SectionComponents/TestimonialCarousel/TestimonialCarousel';

const testimonialCards: TestimonialCardContent[] = [
    {
        statistic: '30%',
        statisticDescription: 'savings on every mile',
        author: {
            name: 'Christian Reisch',
            title: 'Co-owner',
            quote: 'We love Geotab! When I talk to other Domino’s Pizza franchisees and they ask me how it’s going with our fleet, I tell them I cannot imagine having these cars without the technology installed. If you get a fleet of cars, then you need to have Geotab.',
        },
        company: 'Team Goliath Inc. d/b/a Domino’s Pizza',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/dominos-team-goliath/',
        },
    },
    {
        statistic: '$1.3M',
        statisticDescription: 'in potential savings with fleet electrification',
        author: {
            name: 'Yves Mador',
            title: 'Senior Officer',
            quote: 'The EVSA was a very valuable exercise. Thanks to the analysis, departments have more confidence in acquiring EVs.',
        },
        company: 'Transportation and Alternative Fuels, Natural Resources Canada',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/natural-resources-canada/',
        },
    },
    {
        statistic: '30%',
        statisticDescription: 'reduction in insurance premium costs',
        author: {
            name: 'Leslie Tarble',
            title: 'President and Co-Owner',
            quote: 'As a small business, the research and experience that they have saved me countless hours and allows me to make informed and quick decisions. I truly value and trust their expertise in the space.',
        },
        company: '3 Sisters Logistics',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/3-sisters-logistics/',
        },
    },
    {
        statistic: '90K',
        statisticDescription: 'annual reduction in fuel and maintenance costs',
        author: {
            name: 'Eric Gardner',
            title: 'Motor Pool Manager',
            quote: 'Our governor limits idling, but we couldn’t know if people were doing it. I was really surprised at how much idling our drivers were doing and how easy it was to affect change.',
        },
        company: 'State of Utah',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/white-paper/rolling-out-telematics-in-government-fleet/',
        },
    },
    {
        statistic: '59%',
        statisticDescription: 'reduction in idling costs',
        author: {
            name: 'Jonathan Hamilton',
            title: 'Director of Fleet Safety and Compliance',
            quote: 'We’re aligned on our overall values which makes it really easy and enjoyable to work with Geotab. They have a smart team who are always willing to help, and we appreciate the strong partnership.',
        },
        company: 'California Freight',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/california-freight/',
        },
    },
    {
        statistic: '40%',
        statisticDescription: 'reduction in speeding',
        author: {
            name: 'Michael Kidwell',
            title: 'President of Branch Operations',
            quote: 'Geotab makes my day work!',
        },
        company: 'Wiseway Supply Ltd.',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/wiseway-supply/',
        },
    },
    {
        statistic: '90%',
        statisticDescription: 'reduction in high risk drivers',
        author: {
            name: 'Deniz Tuna',
            title: 'Sales and Fleet Health and Safety Manager',
            quote: 'MyGeotab’s unified view of individual and organizational data and insights helps PepsiCo TR proactively manage risks, improve our metrics, elevate safety culture and promote care for our drivers and other road users.',
        },
        company: 'PepsiCo TR',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/uk/case-study/pepsico/',
        },
    },
    {
        statistic: '71%',
        statisticDescription: 'improvement in driver safety performance',
        author: {
            name: 'Ken Venzke',
            title: 'Division Manager',
            quote: 'We\'ve improved our driver score by 71% over the last 18 months by utilizing Geotab\'s technology. Having this technology has done more than just keep us safe, it\'s kept us cutting-edge in a very competitive industry.',
        },
        company: 'Wright Tree Services',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/wright-tree-services/',
        },
    },
    {
        statistic: '70K',
        statisticDescription: 'saved on fuel and maintenance',
        author: {
            name: 'Dan Haseman',
            title: 'Logistics Coordinator',
            quote: 'One of the top challenges for us was to gather all the information we were getting and build that into usable and quantifiable information that could help us make better business decisions.',
        },
        company: 'Solaris Oilfield Infrastructure',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/solaris-oilfield-infrastructure-integrated-fleet/',
        },
    },
    {
        statistic: '700',
        statisticDescription: 'vehicles up from 125 in four years',
        author: {
            name: 'Kevin Henry',
            title: 'Director of Fleet',
            quote: 'We were using another telematics provider when I came on board, but I really didn’t like their program…I wanted to make a switch. With Geotab, we have really great data, and we want to be able to analyze it in specific ways.',
        },
        company: 'Centerline Communications',
        button: {
            text: 'Read more',
            link: 'https://www.geotab.com/case-study/centerline-communications/',
        },
    },
];

const classes = {
    testimonialCarouselContainer: {
        paddingTop: '4rem',
        paddingBottom: '2rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingTop: '3rem',
        },
    },
} as const;

export default function TestimonialCarouselCustomSection({ headerSection }: CustomSectionComponentProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    return (
        <div css={classes.testimonialCarouselContainer}>
            <Stack gap={3} css={{ marginBottom: '2rem' }}>
                <Text variant='h5' color='activePrimary' align='center'>
                    Why businesses choose Geotab
                </Text>
                <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} color='primary' align='center'>
                    25+ Years of fleet management excellence
                </Heading>
            </Stack>
            <TestimonialCarousel
                key={`carousel1 - ${isMobile}`}
                testimonialCards={testimonialCards.slice(0, 5)}
                scrollDirection='left'
                scrollSpeed={0.5}
                autoplay
            />
            <TestimonialCarousel
                key={`carousel2 - ${isMobile}`}
                testimonialCards={testimonialCards.slice(5)}
                scrollDirection='right'
                scrollSpeed={0.5}
                autoplay
            />
        </div>
    );
}
